/* Floating Chat Widget Styles */
.chat-widget-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
  }
  
  .chat-widget-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 65px;
    height: 65px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .chat-widget {
    width: 300px;
    height: 400px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    padding-bottom: 10px;
  }
  
  .close-button {
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    font-size: 18px;
  }
  
  .chat-list {
    overflow-y: auto;
    flex-grow: 1;
  }
  
  .chat-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .chat-item:hover {
    background-color: #f5f5f5;
  }
  
  .chat-details {
    width: 300px;
    height: 400px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .chat-messages {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .message {
    padding: 8px;
    margin: 5px 0;
    background-color: #e1f7d5;
    border-radius: 5px;
  }
  /* Add icon sizes if needed */
.chat-widget-button i {
    font-size: 42px; /* Adjust size */
    color: white;
  }
  
  .close-button i {
    font-size: 24px; /* Adjust size for close button */
    color: #888;
  }
  
  