// 
// Contacts.scss
//

.contact-links{
  a{
    color: var(--#{$prefix}body-color);
  }
}

// profile

.profile-user-wid{
  margin-top: -50px;
}