ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

input[type="checkbox"][id^="myCheckbox"] {
  display: none;
}
.custom-checkbox {
  border: 1px solid #ddd;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

.custom-checkbox:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid white;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}
.form-control{
  line-height: 2.5 !important;
}
.custom-checkbox img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}
.custom-datepicker-popper {
  z-index: 9999 !important; /* Ensure it appears above other elements */
}

.css-1nmdiq5-menu{
  z-index: 2000;
}
input[type="checkbox"]:checked + .custom-checkbox {
  border-color: #556EE6;
}

input[type="checkbox"]:checked + .custom-checkbox:before {
  content: "✓";
  background-color: #556EE6;
  transform: scale(1);
}

input[type="checkbox"]:checked + .custom-checkbox img {
  transform: scale(0.9);
}

/*second*/


.custom-checkbox-card {
  border: 1px solid #ddd !important;
  cursor: pointer;
  transition: border-color 0.3s;
}

.custom-checkbox-card.checked {
  border-color: #556EE6 !important;
  background-color: #f1f0f0;
}

.custom-checkbox-card.checked:after {
  content: "✓";
  background-color: #556ee6; /* Background color for the circle */
  border-radius: 50%; /* Make it a circle */
  width: 20px; /* Adjust the size of the circle */
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Text color (for the checkmark) */
  position: absolute;
  top: 10px;
  right: 10px;
}

.custom-checkbox-card.checked:after::before {
  content: "✓"; /* Checkmark symbol */
}

.custom-horizontal-line {
  border-top: 1px solid #ddd;
  width: 90%; /* Adjust the width as needed */
  margin: 20px auto; /* Center the line */
}

.custom-checkbox-card.disabled {
  opacity: 0.5; /* Reduced opacity for a disabled look */
  pointer-events: none; /* Disable click events */
}

/* Animated Button */

.container {
  width: 200px;
  height: 100%;
  margin: 0 auto 0;
  perspective: 1000;
  backface-visibility: hidden;
  background: #fff;
}
.p-8{
  padding:1rem 8rem 8rem 8rem !important
}
.pulse-button {
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 1.3em;
  font-weight: light;
  font-family: 'Trebuchet MS', sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
  letter-spacing: -1px;
  color: white;
  border: none;
  border-radius: 50%;
  background: #5a99d4;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(50, 100, 150, .5);
  animation: pulse 2s infinite;
}

.pulse-button:hover {
  animation: none;
}

@keyframes pulse {
  0% {
    transform: scale(.9);
  }
  100% {
    transform: scale(.9) translate(0);
    box-shadow: 0 0 0 20px rgba(50, 100, 150, 0);
  }
}


/* Card disable */
.card-body.disabled{
  opacity: 0.5;/* Reduced opacity for a disabled look */
  pointer-events: none; /* Disable click events */
}
.custome-disabled{
  opacity: 0.5;/* Reduced opacity for a disabled look */
  pointer-events: none; /* Disable click events */
}

